import React, { FC } from 'react';
import styled from 'styled-components';
import { Header } from './header';
import { StyledPage } from './page';
import { Spinner } from '../components/spinner';
import { Button } from '../components/buttons';
import { useAuth } from '../use-auth';

const StyledAuthenticatedLayout = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
`;

const StyledUnauthenticatedPage = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Layout: FC = ({ children }) => {
  const authState = useAuth();
  console.log('<Layout />', authState);

  if (authState.pending) {
    return (
      <StyledAuthenticatedLayout>
        <Header />
        <StyledUnauthenticatedPage>
          <Spinner />
        </StyledUnauthenticatedPage>
      </StyledAuthenticatedLayout>
    );
  }

  if (!authState.authenticated) {
    return (
      <StyledAuthenticatedLayout>
        <Header />
        <StyledUnauthenticatedPage>
          <Button color={'blue'} onClick={authState.login}>
            Login
          </Button>
        </StyledUnauthenticatedPage>
      </StyledAuthenticatedLayout>
    );
  }

  return (
    <StyledAuthenticatedLayout>
      <Header />
      <StyledPage>{children}</StyledPage>
    </StyledAuthenticatedLayout>
  );
};
