import React, { useEffect } from 'react';

export const useOutsideClick = (
  ref: React.MutableRefObject<HTMLElement>,
  callback: () => void
): void => {
  const handleClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if (ref.current && !ref.current.contains(target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
