import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Profile } from './profile';
import { theme } from '../components/theme/default-theme';
import { Logo } from './logo';
import { useIsVerticallyScrolled } from './use-is-vertically-scrolled';
import { Anchor } from '../components/anchor';

const StyledHeader = styled.header<{ isScrolled: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 7rem;
  background: ${theme.body.primary.background};
  border-bottom: 1px solid ${theme.border.primary};

  box-shadow: none;
  transition: box-shadow 0.1s ease;

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      box-shadow: ${theme.header.shadow};
    `}

  // 8rem + 768px + 8rem
  @media (max-width: 1024px) {
    padding-left: 0;
  }
`;

const StyledLogoLink = styled(Anchor)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  color: ${theme.body.primary.foreground};
  font-size: 1.5rem;

  cursor: pointer;
  padding: 0.5rem 1rem;
  &:focus {
    border-radius: ${theme.border.radius};
    ${theme.focus};
  }

  div {
    margin-right: 0.5rem;
  }
`;

const StyledToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoLink = () => {
  return (
    <StyledLogoLink to={'/'}>
      <Logo color={theme.logo.foreground} />
      modaq
    </StyledLogoLink>
  );
};

export const Header: FC = ({ children }) => {
  const isScrolled = useIsVerticallyScrolled();
  return (
    <StyledHeader isScrolled={isScrolled}>
      <StyledToolbar>
        <LogoLink />
        {children}
      </StyledToolbar>
      <Profile />
    </StyledHeader>
  );
};
