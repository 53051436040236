import React, { FC } from 'react';
import styled from 'styled-components';

const StyledLogo = styled.div`
  background-color: #000;

  width: 1.5rem;
  height: 1.5rem;

  background-image: linear-gradient(
    to right top,
    #fff 0%,
    #fff 25%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 50%,
    #fff 50%,
    #fff 75%,
    rgba(255, 255, 255, 0) 75%
  );
  background-size: 18% 18%;
  border-radius: 50%;
`;

export const Logo: FC<{ color?: string }> = ({ color = '#fff' }) => {
  return <StyledLogo />;
};
