import styled from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';
import { theme } from './theme/default-theme';
import {
  applyColor,
  applyFontSize,
  applyFontWeight,
  applyScreenSize,
  applyTruncate,
  FontSize,
  FontWeight,
  ScreenSize,
  TextColor,
} from './attributes';

export const Anchor = styled(NavHashLink)<{
  size?: FontSize;
  weight?: FontWeight;
  screenSize?: ScreenSize;
  truncate?: boolean;
  color?: TextColor;
}>`
  ${({ size }) => applyFontSize(size)}
  ${({ weight }) => applyFontWeight(weight)}
  ${({ screenSize }) => applyScreenSize(screenSize)}
  ${({ truncate = true }) => applyTruncate(truncate)}
  ${({ color }) => applyColor(color)}
  cursor: pointer;
  &:focus {
    ${theme.focus};
  }
`;
