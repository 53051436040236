import React, { FC } from 'react';
import Global from './components/global';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from './layout/layout';

const App: FC = () => {
  return (
    <BrowserRouter>
      <Global />
      <Layout />
    </BrowserRouter>
  );
};

export default App;
