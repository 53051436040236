import { useLayoutEffect, useState } from 'react';

export const useIsVerticallyScrolled = (): boolean => {
  const [isScrolled, setScrolled] = useState<boolean>(false);
  useLayoutEffect(() => {
    const onScroll = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return isScrolled;
};
