import styled, { css } from 'styled-components';
import { theme } from './theme/default-theme';

export type ButtonSize = 'small' | 'default' | 'large';
export type ButtonColor = 'default' | 'blue' | 'green' | 'red';
export type ButtonShape = 'default' | 'square';

const applySize = (size = 'default') => {
  if (size === 'small') {
    return css`
      font-size: 0.8rem;
      padding: 0 1rem;
      height: 1.5rem;
      svg {
        width: 1rem;
        height: 1rem;
        text-align: center;
        vertical-align: middle;
      }
    `;
  }
  if (size === 'default') {
    return css`
      font-size: 1rem;
      padding: 0 1.25rem;
      height: 2rem;
      svg {
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
        vertical-align: middle;
      }
    `;
  }
  if (size === 'large') {
    return css`
      font-size: 1.25rem;
      padding: 0 1.5rem;
      height: 2.5rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        vertical-align: middle;
      }
    `;
  }
  return;
};

const applySquareShape = (size: ButtonSize = 'default') => {
  if (size === 'small') {
    return css`
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;
      svg {
        width: 1rem;
        height: 1rem;
        text-align: center;
        vertical-align: middle;
      }
    `;
  }
  if (size === 'default') {
    return css`
      padding: 0;
      width: 2rem;
      height: 2rem;
      svg {
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
        vertical-align: middle;
      }
    `;
  }
  if (size === 'large') {
    return css`
      padding: 0;
      width: 2.5rem;
      height: 2.5rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        vertical-align: middle;
      }
    `;
  }
  return;
};

const applyColor = (color: ButtonColor = 'default') => {
  return css`
    font-weight: ${theme.buttons[color].weight};

    color: ${theme.buttons[color].foreground};
    g {
      stroke: ${theme.buttons[color].foreground};
    }
    path {
      fill: ${theme.buttons[color].foreground};
    }
    box-shadow: ${theme.buttons[color].shadow};
    border-color: ${theme.buttons[color].border};
    background-color: ${theme.buttons[color].background};

    &:hover {
      box-shadow: ${theme.buttons[color].hover.shadow};
      border-color: ${theme.buttons[color].hover.border};
      background-color: ${theme.buttons[color].hover.background};
      color: ${theme.buttons[color].hover.foreground};
    }

    &:active {
      box-shadow: ${theme.buttons[color].active.shadow};
      border-color: ${theme.buttons[color].active.border};
      background-color: ${theme.buttons[color].active.background};
      color: ${theme.buttons[color].active.foreground};
    }
    &:disabled {
      box-shadow: ${theme.buttons[color].disabled.shadow};
      border-color: ${theme.buttons[color].disabled.border};
      background-color: ${theme.buttons[color].disabled.background};
      color: ${theme.buttons[color].disabled.foreground};
    }
  `;
};

export const Button = styled.button<{
  size?: ButtonSize;
  color?: ButtonColor;
  shape?: ButtonShape;
  border?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  font-weight: 500;
  text-decoration: none;

  border-radius: ${theme.border.radius};
  border: 1px solid transparent;

  ${({ size }) => applySize(size)}
  ${({ color }) => applyColor(color)}
  ${({ size, shape }) => shape === 'square' && applySquareShape(size)}

  ${({ border = true }) =>
    !border &&
    css`
      border: 1px solid transparent;
      box-shadow: none;
    `}

  &:focus {
    ${theme.focus}
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
  }
`;

export const ButtonLink = Button.withComponent('a');
