import { css, FlattenSimpleInterpolation } from 'styled-components';
import { theme } from './theme/default-theme';

export type TextAlign = 'left' | 'center' | 'right';
export const applyTextAlign = (
  textAlign = 'left'
): FlattenSimpleInterpolation => css`
  text-align: ${textAlign};
`;

export type FontSize = 'small' | 'default' | 'large';
export const applyFontSize = (
  fontSize?: FontSize
): FlattenSimpleInterpolation | undefined => {
  switch (fontSize) {
    case 'small':
      return css`
        font-size: 0.8rem;
      `;
    case 'large':
      return css`
        font-size: 1.25rem;
      `;
  }
  return;
};

export type FontWeight = 'light' | 'default' | 'heavy' | 'x-heavy';
export const applyFontWeight = (
  fontWeight?: FontWeight
): FlattenSimpleInterpolation | undefined => {
  switch (fontWeight) {
    case 'light':
      return css`
        font-weight: 300;
      `;
    case 'heavy':
      return css`
        font-weight: 500;
      `;
    case 'x-heavy':
      return css`
        font-weight: 600;
      `;
  }
  return;
};

export type ScreenSize = 'phone' | 'tablet';
export const applyScreenSize = (
  screenSize?: ScreenSize
): FlattenSimpleInterpolation | undefined => {
  switch (screenSize) {
    case 'phone':
      return css`
        @media (max-width: 375px) {
          display: none;
        }
      `;
    case 'tablet':
      return css`
        @media (max-width: 768px) {
          display: none;
        }
      `;
  }
  return;
};

export type TextColor = 'blue' | 'green' | 'red' | 'black' | 'white';
export const applyColor = (
  color?: TextColor
): FlattenSimpleInterpolation | undefined => {
  switch (color) {
    case 'blue':
      return css`
        color: ${theme.body.blue.foreground};
      `;
    case 'green':
      return css`
        color: ${theme.body.green.foreground};
      `;
    case 'red':
      return css`
        color: ${theme.body.red.foreground};
      `;
    case 'black':
      return css`
        color: ${theme.body.primary.foreground};
      `;
    case 'white':
      return css`
        color: ${theme.body.primary.background};
      `;
  }
};

export const applyTruncate = (
  truncate: boolean
): FlattenSimpleInterpolation | undefined => {
  if (truncate) {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
  return;
};
