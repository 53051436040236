/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from 'styled-components';
import { Theme } from './theme';

// basics
const TRUE_BLACK = '#000';
const TRUE_WHITE = '#fff';

// focus
const FOCUS_COLOR = 'hsla(198,76%,47%,0.3)';

// grays
const GRAY_100 = 'hsl(240,9%,15%)';
const GRAY_200 = 'hsl(240,9%,25%)';
const GRAY_300 = 'hsl(240,9%,35%)';
const GRAY_400 = 'hsl(240,9%,47%)';
const GRAY_500 = 'hsl(240,9%,61%)';
const GRAY_600 = 'hsl(240,9%,75%)';
const GRAY_700 = 'hsl(240,9%,90%)';
const GRAY_800 = 'hsl(240,9%,94%)';
const GRAY_900 = 'hsl(240,9%,98%)';

// accents
const RED_100 = 'hsl(3,88%,15%)';
const RED_200 = 'hsl(3,88%,25%)';
const RED_300 = 'hsl(3,88%,35%)';
const RED_400 = 'hsl(3,88%,45%)';
const RED_500 = 'hsl(3,88%,55%)';
const RED_600 = 'hsl(3,88%,65%)';
const RED_700 = 'hsl(3,88%,75%)';
const RED_800 = 'hsl(3,88%,85%)';
const RED_900 = 'hsl(3,88%,95%)';

const BLUE_100 = 'hsl(217,100%,15%)';
const BLUE_200 = 'hsl(217,100%,25%)';
const BLUE_300 = 'hsl(217,100%,35%)';
const BLUE_400 = 'hsl(217,100%,45%)';
const BLUE_500 = 'hsl(217,100%,55%)';
const BLUE_600 = 'hsl(217,100%,65%)';
const BLUE_700 = 'hsl(217,100%,75%)';
const BLUE_800 = 'hsl(217,100%,85%)';
const BLUE_900 = 'hsl(217,100%,95%)';

const GREEN_100 = 'hsl(127,63%,15%)';
const GREEN_200 = 'hsl(127,63%,25%)';
const GREEN_300 = 'hsl(127,63%,35%)';
const GREEN_400 = 'hsl(127,63%,45%)';
const GREEN_500 = 'hsl(127,63%,55%)';
const GREEN_600 = 'hsl(127,63%,65%)';
const GREEN_700 = 'hsl(127,63%,80%)';
const GREEN_800 = 'hsl(127,63%,90%)';
const GREEN_900 = 'hsl(127,63%,95%)';

const YELLOW_100 = 'hsl(46,100%,15%)';
const YELLOW_200 = 'hsl(46,100%,25%)';
const YELLOW_300 = 'hsl(46,100%,35%)';
const YELLOW_400 = 'hsl(46,100%,47%)';
const YELLOW_500 = 'hsl(46,100%,55%)';
const YELLOW_600 = 'hsl(46,100%,69%)';
const YELLOW_700 = 'hsl(46,100%,77%)';
const YELLOW_800 = 'hsl(46,100%,85%)';
const YELLOW_900 = 'hsl(46,100%,95%)';

const NONE = 'none';

// shadows
const BUTTON_SHADOW_HOVER =
  '0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.3)';
const BUTTON_SHADOW_ACTIVE =
  '0 1px 3px 0 rgba(0,0,0,0.3), 0 1px 3px 0 rgba(0,0,0,0.4)';
const INPUT_SHADOW =
  '0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1)';
const HEADER_SHADOW = '0 0 1rem 0 rgba(0,0,0,.1)';
const MENU_SHADOW = '0px 0.25rem 1rem rgba(0, 0, 0, 0.15)';

// specials
const FOCUS = css`
  outline: none;
  box-shadow: transparent 0px 0px 0px 1px, ${FOCUS_COLOR} 0px 0px 0px 3px;
`;

// update animation effect
export const ENTITY_UPDATE_EFFECT = css`
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4), 0 1px 4px 0 rgba(0, 0, 0, 0.4);
`;
export const TABLE_UPDATE_EFFECT = css`
  background: rgba(0, 0, 0, 0.1);
`;

export const theme: Theme = {
  body: {
    primary: {
      foreground: TRUE_BLACK,
      background: TRUE_WHITE,
    },
    secondary: {
      foreground: GRAY_100,
      background: GRAY_900,
    },
    tertiary: {
      foreground: GRAY_200,
      background: GRAY_800,
    },
    blue: {
      foreground: BLUE_500,
      background: BLUE_800,
    },
    red: {
      foreground: RED_500,
      background: RED_800,
    },
    green: {
      foreground: GREEN_500,
      background: GREEN_800,
    },
  },
  border: {
    radius: '5px',
    primary: GRAY_700,
  },
  focus: FOCUS,
  input: {
    foreground: GRAY_100,
    background: TRUE_WHITE,
    border: GRAY_400,
    shadow: INPUT_SHADOW,
    disabled: {
      foreground: GRAY_400,
      background: GRAY_900,
      border: GRAY_700,
      shadow: NONE,
    },
  },
  buttons: {
    default: {
      weight: 400,
      foreground: GRAY_100,
      background: TRUE_WHITE,
      border: GRAY_400,
      shadow: INPUT_SHADOW,
      hover: {
        foreground: TRUE_BLACK,
        background: GRAY_800,
        border: GRAY_200,
        shadow: BUTTON_SHADOW_HOVER,
      },
      active: {
        foreground: TRUE_BLACK,
        background: GRAY_800,
        border: GRAY_200,
        shadow: BUTTON_SHADOW_ACTIVE,
      },
      disabled: {
        foreground: GRAY_400,
        background: GRAY_900,
        border: GRAY_700,
        shadow: NONE,
      },
    },
    blue: {
      weight: 500,
      foreground: TRUE_WHITE,
      background: BLUE_500,
      border: BLUE_300,
      shadow: INPUT_SHADOW,
      hover: {
        foreground: TRUE_WHITE,
        background: BLUE_600,
        border: BLUE_400,
        shadow: BUTTON_SHADOW_HOVER,
      },
      active: {
        foreground: TRUE_WHITE,
        background: BLUE_400,
        border: BLUE_200,
        shadow: BUTTON_SHADOW_ACTIVE,
      },
      disabled: {
        foreground: TRUE_WHITE,
        background: BLUE_800,
        border: BLUE_700,
        shadow: NONE,
      },
    },
    red: {
      weight: 500,
      foreground: TRUE_WHITE,
      background: RED_500,
      border: RED_300,
      shadow: INPUT_SHADOW,
      hover: {
        foreground: TRUE_WHITE,
        background: RED_600,
        border: RED_400,
        shadow: BUTTON_SHADOW_HOVER,
      },
      active: {
        foreground: TRUE_WHITE,
        background: RED_400,
        border: RED_200,
        shadow: BUTTON_SHADOW_ACTIVE,
      },
      disabled: {
        foreground: TRUE_WHITE,
        background: RED_800,
        border: RED_700,
        shadow: NONE,
      },
    },
    green: {
      weight: 500,
      foreground: TRUE_WHITE,
      background: GREEN_500,
      border: GREEN_300,
      shadow: INPUT_SHADOW,
      hover: {
        foreground: TRUE_WHITE,
        background: GREEN_600,
        border: GREEN_400,
        shadow: BUTTON_SHADOW_HOVER,
      },
      active: {
        foreground: TRUE_WHITE,
        background: GREEN_400,
        border: GREEN_200,
        shadow: BUTTON_SHADOW_ACTIVE,
      },
      disabled: {
        foreground: TRUE_WHITE,
        background: GREEN_800,
        border: GREEN_700,
        shadow: NONE,
      },
    },
  },
  status: {
    success: {
      foreground: GREEN_200,
      background: GREEN_800,
    },
    pending: {
      foreground: YELLOW_200,
      background: YELLOW_700,
    },
    error: {
      foreground: RED_300,
      background: RED_900,
    },
  },
  dropzone: {
    foreground: GRAY_400,
    background: GRAY_800,
    active: GRAY_700,
    disabled: GRAY_900,
    stripe: TRUE_WHITE,
  },
  table: {
    highlight: GRAY_800,
    updateEffect: TABLE_UPDATE_EFFECT,
  },
  entity: {
    updateEffect: ENTITY_UPDATE_EFFECT,
  },
  menu: {
    shadow: MENU_SHADOW,
  },
  header: {
    shadow: HEADER_SHADOW,
  },
  logo: {
    foreground: TRUE_BLACK,
  },
};
