import { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FirebaseContext } from './firebase-context';

export type AuthState =
  | {
      type: 'authenticated';
      authenticated: true;
      pending: false;
      authUser: firebase.User;
      logout: () => void;
    }
  | {
      type: 'not-authenticated';
      authenticated: false;
      pending: false;
      login: () => void;
    }
  | {
      type: 'pending';
      authenticated: false;
      pending: true;
      login: () => void;
    };

export const useAuth = (): AuthState => {
  const firebaseApp = useContext(FirebaseContext);

  const [authUser, setAuthUser] = useState<firebase.User | null>(null);
  const [pending, setPending] = useState<boolean>(true);

  const auth = firebaseApp?.auth();
  const provider = new firebase.auth.GoogleAuthProvider();

  const login = async () => {
    await auth?.signInWithRedirect(provider);
  };

  const logout = async () => {
    await auth?.signOut();
  };

  const pendingState: AuthState = {
    type: 'pending',
    authenticated: false,
    pending: true,
    login,
  };

  const notAuthenticated: AuthState = {
    type: 'not-authenticated',
    authenticated: false,
    pending: false,
    login,
  };

  useEffect(() => {
    if (auth) {
      const cleanup = auth.onAuthStateChanged(
        (authUser: firebase.User | null) => {
          setPending(false);
          authUser ? setAuthUser(authUser) : setAuthUser(null);
        }
      );
      return () => {
        cleanup();
      };
    }
  }, [auth]);

  if (authUser) {
    return {
      type: 'authenticated',
      authenticated: true,
      pending: false,
      authUser: authUser,
      logout,
    };
  }
  if (pending) {
    return pendingState;
  }

  return notAuthenticated;
};
