import React, { FC } from 'react';
import styled from 'styled-components';

import { useAuth } from '../use-auth';
import { theme } from '../components/theme/default-theme';

import {
  Menu,
  MenuDivider,
  MenuDropDown,
  MenuItem,
  MenuSummary,
} from '../components/menu';

import { Button } from '../components/buttons';
import { Spinner } from '../components/spinner';

const ProfileIdentity = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const ProfileUserName = styled.div`
  white-space: nowrap;
`;

const ProfileEmail = styled.div`
  white-space: nowrap;
  font-size: 0.75rem;
`;

const ProfileImage = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${theme.body.primary.background};
`;

const LargeProfileImage = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: ${theme.body.primary.background};
`;

const StyledSpinner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 1rem;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Profile: FC = () => {
  const authState = useAuth();

  if (authState.pending) {
    return (
      <StyledSpinner>
        <Spinner />
      </StyledSpinner>
    );
  }

  if (!authState.authenticated) {
    return null;
  }

  const { authUser, logout } = authState;
  const { displayName, email, photoURL } = authUser;

  return (
    <Menu>
      <MenuSummary>
        {photoURL && (
          <ProfileImage
            data-type="profile-image"
            src={photoURL}
            alt="Profile"
          />
        )}
      </MenuSummary>
      <MenuDropDown align="right">
        <MenuItem>
          {photoURL && <LargeProfileImage src={photoURL} alt="Profile" />}
          <ProfileIdentity>
            {displayName && <ProfileUserName>{displayName}</ProfileUserName>}
            {displayName !== email && <ProfileEmail>{email}</ProfileEmail>}
          </ProfileIdentity>
        </MenuItem>
        <MenuDivider />
        <MenuItem>
          <Button onClick={logout}>Logout</Button>
        </MenuItem>
      </MenuDropDown>
    </Menu>
  );
};
