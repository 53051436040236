import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

import { createContext, FC } from 'react';

export const FirebaseContext = createContext<firebase.app.App | null>(null);

export const FirebaseProvider: FC<{ app: firebase.app.App }> = ({
  app,
  children,
}) => {
  return (
    <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
  );
};
