import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';
import { theme } from './theme/default-theme';
import { useOutsideClick } from './use-outside-click';
import { Button, ButtonSize } from './buttons';
import { Anchor } from './anchor';

type MenuAlignment = 'left' | 'right' | 'center';

const Details = styled.details`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not([open]) > *:not(summary) {
    display: none !important;
  }
`;

export const MenuSummary = styled.summary`
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;

  padding: 0.25rem 1rem;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    border-radius: ${theme.border.radius};
    ${theme.focus};
  }
`;

const SummaryButton = styled(Button)`
  &::-webkit-details-marker {
    display: none;
  }
`;

export const MenuSummaryButton: FC<{ size: ButtonSize }> = ({
  children,
  size,
}) => (
  <SummaryButton as="summary" size={size}>
    {children}
  </SummaryButton>
);

export const MenuDropDown = styled.div<{ align?: MenuAlignment }>`
  --indent: 1.5rem;
  --minWidth: 15rem;

  display: flex;
  flex-direction: column;
  position: absolute;

  top: calc(100% + 0.5rem);

  ${({ align }) => {
    if (align === 'left') {
      return css`
        left: 0;
      `;
    }
    if (align === 'right') {
      return css`
        right: 0;
      `;
    }
    return css`
      right: calc(50% - var(--minWidth) / 2);
    `;
  }}

  min-width: var(--minWidth);
  margin: 0;
  border-radius: ${theme.border.radius};
  border: 1px solid ${theme.border.primary};
  background: ${theme.body.secondary.background};
  box-shadow: ${theme.menu.shadow};
  z-index: 2;

  padding: 0.5rem 0;

  &::before {
    content: '';
    position: absolute;

    ${({ align }) => {
      if (align === 'left') {
        return css`
          left: calc(var(--indent) - 1px);
        `;
      }
      if (align === 'right') {
        return css`
          right: calc(var(--indent) - 1px);
        `;
      }
      return css`
        margin: 0 auto;
        left: 0;
        right: 0;
      `;
    }}

    width: 0px;
    height: 0px;
    top: 0px;
    transform: translateY(-100%);
    pointer-events: none;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent transparent ${theme.border.primary};
    border-image: initial;
  }

  &::after {
    content: '';
    position: absolute;
    ${({ align }) => {
      if (align === 'left') {
        return css`
          left: var(--indent);
        `;
      }
      if (align === 'right') {
        return css`
          right: var(--indent);
        `;
      }
      return css`
        margin: 0 auto;
        left: 0;
        right: 0;
      `;
    }}
    width: 0px;
    height: 0px;
    top: 0px;
    transform: translateY(-100%);
    pointer-events: none;
    border-width: calc(0.5rem - 1px);
    border-style: solid;
    border-color: transparent transparent ${theme.body.secondary.background};
    border-image: initial;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0.5rem 1rem;
  border: 0;

  white-space: nowrap;
`;

export const MenuDivider = styled.div`
  margin: 0.25rem 0;
  height: 1px;
  background: ${theme.border.primary};
`;

export const MenuItemLink = styled(Anchor)`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;

  color: ${theme.body.primary.foreground};
  background: transparent;

  &:hover {
    color: ${theme.buttons.blue.foreground};};
    background: ${theme.buttons.blue.background};};
  }

  &:focus {
    border-radius: ${theme.border.radius};
    ${theme.focus};
  }
`;

export const Menu: FC = ({ children }) => {
  const ref = useRef() as React.MutableRefObject<HTMLDetailsElement>;

  useOutsideClick(ref, () => {
    ref?.current?.removeAttribute('open');
  });

  return <Details ref={ref}>{children}</Details>;
};
