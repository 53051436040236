import styled from 'styled-components';

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 64rem;
  margin: 1rem 8rem 16rem 8rem;

  // 8rem + 768px + 8rem
  @media (max-width: 1024px) {
    margin: 1rem;
  }
`;
