import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { theme } from './theme/default-theme';

export default createGlobalStyle`
   ${normalize()}

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    position: relative;
  }

  body,
  html,
  #root {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100vw;
    color: ${theme.body.primary.foreground};
    background: ${theme.body.primary.background};

    @media (max-width: 375px) {
       font-size: 12px;
    }
  }

  #root {
    display: flex;
    flex-direction: column;
  }
`;
